// banner 720 * 256
.indexTopBanner {
  @banner_h: 256px;
  @banner_real_h: 400px;
  @arrow_size: 36px;
  @arrow_offset: 20px;
  @margin_top: @banner_h - @banner_real_h;
  @arrow_z: 1;
  overflow: hidden;
  height: @banner_h;
  .slick-slider {
    width: 100%;
    height: @banner_h;
    overflow: hidden;
    .slick-arrow {
      z-index: @arrow_z;
      width: @arrow_size;
      height: @arrow_size;
      &:before {
        font-size: @arrow_size;
      }
      &.slick-prev {
        left: @arrow_offset;
      }
      &.slick-next {
        right: @arrow_offset;
      }
    }
    .slick-dots {
      bottom: 0;
      @size: 8px;
      li {
        margin: 0 2px 4px;
        button:before {
          color: #fff;
          content: ' ';
          width: @size;
          height: @size;
          background: #fff;
          border-radius: 50%;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
    .slick-list {
      .slick-slide {
        padding: 0 10px;
      }
      .slick-slide,
      .slick-slide .topSliderItem {
        height: @banner_h;
        border-radius: 4px;
        overflow: hidden;
      }
      height: 100%;
      .slick-track {
        height: 100%;
      }
    }
    .topSliderItem {
      &.custom{
        .custom-img{
          width: 100%;
          margin: @margin_top auto 0;
        }
      }
      width: 100%;
      height: @banner_h;
      position: relative;
      .bg,
      .img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 4px;
      }
      .bg .main {
        margin-top: 49px;
        overflow: hidden;
        @cover_h: 160px;
        height: @cover_h;
        .topSliderItemL {
          float: left;
          width: @cover_h;
          height: @cover_h;
          margin: 0 0.6rem 0 0.4rem;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .topSliderItemLR {
        padding-top: 40px;
        float: left;
        width: 50%;
        .title {
          font-size: 14px;
          line-height: 120%;
          height: 1.2em;
          color: #fff;
          font-weight: 400;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .textCon {
          margin-top: 16px;
          font-size: 12px;
          font-weight: normal;
          line-height: 1.4em;
          // height: 4.2em;
          color: rgba(255, 255, 255, 0.96);
          // overflow: hidden;
        }
      }
    }
  }
}
